<template>
  <div>
    <b-alert v-if="isDataLoading" show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <template v-else-if="table_data" v-for="(brand, i) in table_data">
      <v-row :key="`route_kpi_${i}`">
        <v-col cols="12" sm="12" md="12">
          <h6>{{ brand.name }}</h6>
          <div class="table_container" style="width: 100%;overflow-x: auto;">
            <table
              class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense text-center"
            >
              <tr>
                <th
                  class="stats-th"
                  :key="`route_kpi_${brand.id}_column_table`"
                ></th>
                <template
                  v-for="(surveyQuestion, columnKey) in brand.table_columns"
                >
                  <th
                    class="stats-th"
                    :key="`route_kpi_${brand.id}_column_table_${columnKey}`"
                  >
                    {{ surveyQuestion.name }}
                  </th>
                </template>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("Today") }}</b>
                </td>
                <template
                  v-for="(surveyQuestion, columnKey) in brand.table_columns"
                >
                  <td
                    :class="
                      `stats-th ${calcBackgroundColor(
                        brand.today_list[surveyQuestion.id]
                      )}`
                    "
                    :key="`route_kpi_${brand.id}_column_table_${columnKey}`"
                  >
                    <template
                      v-if="
                        Object.keys(brand.today_list)
                          .map(el => parseInt(el))
                          .includes(surveyQuestion.id)
                      "
                    >
                      {{
                        numberFormatEsCustomDecimalCount(
                          brand.today_list[surveyQuestion.id]?.sumOfValueStr,
                          1
                        )
                      }}
                      vs
                      {{
                        numberFormatEsCustomDecimalCount(
                          brand.today_list[surveyQuestion.id]
                            ?.sumOfkpiObjectiveValueStr,
                          1
                        )
                      }}
                    </template>
                    <span v-else>&nbsp;</span>
                  </td>
                </template>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("Month") }}</b>
                </td>
                <template
                  v-for="(surveyQuestion, columnKey) in brand.table_columns"
                >
                  <td
                    :class="
                      `stats-th ${calcBackgroundColor(
                        brand.month_list[surveyQuestion.id]
                      )}`
                    "
                    :key="`route_kpi_${brand.id}_column_table_${columnKey}`"
                  >
                    <template
                      v-if="
                        Object.keys(brand.month_list)
                          .map(el => parseInt(el))
                          .includes(surveyQuestion.id)
                      "
                    >
                      {{
                        numberFormatEsCustomDecimalCount(
                          brand.month_list[surveyQuestion.id]?.sumOfValueStr,
                          1
                        )
                      }}
                      vs
                      {{
                        numberFormatEsCustomDecimalCount(
                          brand.month_list[surveyQuestion.id]
                            ?.sumOfkpiObjectiveValueStr,
                          1
                        )
                      }}
                    </template>
                    <span v-else>&nbsp;</span>
                  </td>
                </template>
              </tr>
            </table>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";

export default {
  name: "routeKPIs",
  props: ["worksessionId"],
  components: {},

  data: function() {
    return {
      isDataLoading: false,
      table_data: null
    };
  },

  watch: {},

  computed: {},

  methods: {
    calcBackgroundColor(cellItem) {
      if (cellItem) {
        let objectivo = !isNaN(cellItem.sumOfkpiObjectiveValueStr)
          ? parseFloat(cellItem.sumOfkpiObjectiveValueStr)
          : null;
        let value = parseFloat(cellItem.sumOfValueStr);
        if (
          objectivo !== null &&
          !isNaN(objectivo) &&
          value !== null &&
          !isNaN(value)
        ) {
          if (value >= objectivo) return "back_green";
          else return "back_red";
        }
      }
    },
    async init() {
      this.isDataLoading = true;
      let fetch_url = `worksession/get_route_kpis/${this.worksessionId}`;
      try {
        const { table_data } = await ApiService.get(fetch_url);
        this.table_data = table_data;
        this.isDataLoading = false;
      } catch (e) {
        this.isDataLoading = false;
        this.table_data = [];
      }
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style></style>
