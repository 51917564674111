<template>
  <div>
    <modal name="modal-add-clients" :width="`99%`" :height="`auto`" scrollable>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">Add Clients</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="7">
                <span class="text-h6">From a Route</span>
                <v-row
                  class="d-flex justify-content-between align-center text-center"
                >
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selectedRouteIds"
                      v-bind:label="$t('route')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_routes"
                      :loading="isRouteSearching"
                      :items="filtered_routes"
                      multiple
                      chips
                      clearable
                      filled
                      outlined
                      hide-no-data
                      hide-details
                      return-object
                      @change="onChangeRoutes"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-menu
                      v-model="select_date_menu"
                      ref="date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedDate"
                          v-bind:label="$t('Date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedDate"
                        no-title
                        @input="select_date_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-btn color="primary" @click="onClickLoadRoutes">{{
                      $t("Load")
                    }}</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <template v-for="(routeItem, kRoute) in selectedRoutes">
                      <v-chip
                        :key="kRoute"
                        class="ma-2"
                        close
                        @click:close="onClickRemoveRoute(routeItem.id)"
                      >
                        {{ ellipsis(routeItem.name, 30) }}
                      </v-chip>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <span class="text-h6">From a POS</span>
                <v-row>
                  <v-col cols="12" sm="12" md="3">
                    <v-checkbox
                      v-model="isSelectedMyRoute"
                      :label="`de mi ruta`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="5">
                    <v-autocomplete
                      v-model="selectedPosIds"
                      label="POS"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_pos"
                      :loading="isPOSSearching"
                      :items="filtered_pos"
                      multiple
                      chips
                      clearable
                      filled
                      outlined
                      hide-no-data
                      hide-details
                      return-object
                      @change="onChangePOS"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="d-flex align-center text-center"
                  >
                    <v-btn color="primary" @click="onClickLoadPOS">{{
                      $t("Load")
                    }}</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <template v-for="(posItem, kPos) in selectedPOS">
                      <v-chip
                        :key="kPos"
                        class="ma-2"
                        close
                        @click:close="onClickRemovePos(posItem.id)"
                      >
                        {{ ellipsis(posItem.name, 30) }}
                      </v-chip>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-btn color="success" @click="onClickAddSelection">{{
                  $t("Add Selection")
                }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ve-table
                  id="datatable-additionalpos"
                  fixed-header
                  max-height="calc(100vh - 330px)"
                  :columns="columns"
                  :table-data="tableData"
                  :border-around="true"
                  :border-x="true"
                  :border-y="true"
                  :scroll-width="1000"
                  :sort-option="sortOption"
                  :cell-style-option="cellStyleOption"
                  row-key-field-name="rowKey"
                  :checkbox-option="checkboxOption"
                />
                <div v-show="showEmpty" class="empty-data">Data Empty.</div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
    <ConfirmDialogAddClient
      ref="confirmDialogAddClient"
      :visitTypes="visitTypes"
      :confirmSelectionCriteria="confirmSelectionCriteria"
    />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError, ellipsis } from "@/utils";
import _ from "lodash";
import ConfirmDialogAddClient from "./ConfirmDialogAddClient.vue";

export default {
  name: "DialogAddClient",
  props: ["worksessionId", "parentInit", "visitTypes", "mainRoute"],
  components: {
    ConfirmDialogAddClient
  },
  data: function() {
    return {
      mergedPosData: [],
      mergedPosId: [],
      current_row: null,
      select_date_menu: false,
      reactive: false,

      tableData: [],
      sourceData: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          this.selectedRowKeys = selectedRowKeys;
        },
        // selected all change event
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.selectedRowKeys = selectedRowKeys;
        }
      },
      filterQueryParams: {
        route_name: [],
        brand_name: [],
        pos_name: [],
        pos_town: [],
        pos_postalcode: [],
        pos_address: [],
        chain_name: [],
        channel_name: []
      },
      filterCriteria: {
        route_name: {},
        brand_name: {},
        pos_name: {},
        posId: {},
        pos_town: {},
        pos_postalcode: {},
        pos_address: {},
        chain_name: {},
        channel_name: {},
        recover: {},
        lastVisit: {},
        alreadyAdded: {}
      },
      filterable_col_list: [
        "route_name",
        "brand_name",
        "pos_name",
        "posId",
        "pos_town",
        "pos_postalcode",
        "pos_address",
        "chain_name",
        "channel_name",
        "recover",
        "lastVisit",
        "alreadyAdded"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let row_col_classname = [];
          if (row.lastVisit && row.isVisitedBeforeAweek) {
            console.log("row - ", row.lastVisit, column);
            row_col_classname.push("table-body-cell-addclient-pink");
            if (column.key === "lastVisit") {
              row_col_classname.push("table-body-cell-addclient-color-red");
            }
          }
          return row_col_classname.join(" ");
        }
      },
      showEmpty: false,
      fetchUrlFilterList: "worksession/getfilterlist",

      search_routes: null,
      isRouteSearching: false,
      filtered_routes: [],
      selectedRouteIds: [],
      selectedRoutes: [],
      selectedDate: null,

      search_pos: null,
      isPOSSearching: false,
      filtered_pos: [],
      selectedPosIds: [],
      selectedPOS: [],

      selectedRowKeys: [],
      isSelectedMyRoute: false
    };
  },
  watch: {
    async search_routes(val) {
      await this.get_search_routes(val);
    },
    async search_pos(val) {
      await this.get_search_pos(val);
    },
    async mergedPosData(newVal, oldVal) {
      await this.get_search_pos("");
    },
    async isSelectedMyRoute(newVal, oldVal) {
      await this.get_search_pos("");
    }
  },
  computed: {
    col_checkbox() {
      return {
        field: "",
        key: "a",
        type: "checkbox",
        title: "",
        width: 50,
        align: "center",
        fixed: "left"
      };
    },
    col_route() {
      return {
        key: "route_name",
        title: this.$t("route"),
        field: "route_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["route_name"]}
                  dataList={this.filterQueryParams["route_name"]}
                  filterCriteria={this.filterCriteria["route_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brand() {
      return {
        key: "brand_name",
        title: `${this.$t("brands.brand")} ${this.$t("salesforce.name")}`,
        field: "brand_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["brand_name"]}
                  dataList={this.filterQueryParams["brand_name"]}
                  filterCriteria={this.filterCriteria["brand_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posname() {
      return {
        key: "pos_name",
        title: `POS ${this.$t("salesforce.name")}`,
        field: "pos_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["pos_name"]}
                  dataList={this.filterQueryParams["pos_name"]}
                  filterCriteria={this.filterCriteria["pos_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: `ID POS`,
        field: "posId",
        align: "left",
        width: 150,
        sortBy: ""
      };
    },
    col_postown() {
      return {
        key: "pos_town",
        title: `POS ${this.$t("town")}`,
        field: "pos_town",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["pos_town"]}
                  dataList={this.filterQueryParams["pos_town"]}
                  filterCriteria={this.filterCriteria["pos_town"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_town")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_postalCode() {
      return {
        key: "pos_postalcode",
        title: `POS CP`,
        field: "pos_postalcode",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["pos_postalcode"]}
                  dataList={this.filterQueryParams["pos_postalcode"]}
                  filterCriteria={this.filterCriteria["pos_postalcode"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "pos_postalcode")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address() {
      return {
        key: "pos_address",
        title: `POS ${this.$t("address")}`,
        field: "pos_address",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.po && row.po.address}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["pos_address"]}
                  dataList={this.filterQueryParams["pos_address"]}
                  filterCriteria={this.filterCriteria["pos_address"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_address")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_chain_name() {
      return {
        key: "chain_name",
        title: `POS ${this.$t("chain")}`,
        field: "chain_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["chain_name"]}
                  dataList={this.filterQueryParams["chain_name"]}
                  filterCriteria={this.filterCriteria["chain_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_channel_name() {
      return {
        key: "channel_name",
        title: `POS ${this.$t("channel")}`,
        field: "channel_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["channel_name"]}
                  dataList={this.filterQueryParams["channel_name"]}
                  filterCriteria={this.filterCriteria["channel_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_recover() {
      return {
        key: "recover",
        title: this.$t("recover"),
        field: "recover",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.recover ? "YES" : "NO"}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_lastVisit() {
      return {
        key: "lastVisit",
        title: this.$t("lastVisit"),
        field: "lastVisit",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span class="text-bold">{row.formatedLastVisit}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_alreadyAdded() {
      return {
        key: "alreadyAdded",
        title: this.$t("alreadyAdded"),
        field: "alreadyAdded",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span class="text-bold">{row.alreadyAdded ? "YES" : "NO"}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_checkbox,
        this.col_route,
        this.col_brand,
        this.col_posId,
        this.col_posname,
        this.col_postown,
        this.col_postalCode,
        this.col_address,
        this.col_chain_name,
        this.col_channel_name,
        this.col_recover,
        this.col_lastVisit,
        this.col_alreadyAdded
      ];
    }
  },
  methods: {
    ellipsis,
    async get_search_routes(val) {
      let fetch_url = `worksession/get_search_list/route/${val}`;
      if (!val) {
        fetch_url = `worksession/get_search_list/route/null`;
      }
      const resp = await ApiService.post(fetch_url);
      this.filtered_routes = resp.data;
    },
    async get_search_pos(val) {
      let fetch_url = `worksession/get_search_list/posfilter/${val}`;
      if (!val) {
        fetch_url = `worksession/get_search_list/posfilter/null`;
      }
      if (this.mergedPosData) {
        const resp = await ApiService.post(fetch_url, {
          mergedPosId: this.mergedPosId,
          isSelectedMyRoute: this.isSelectedMyRoute,
          routeId: this.mainRoute.id
        });
        this.filtered_pos = resp.data;
      }
    },
    async showModal(mergedPosData) {
      if (mergedPosData) {
        let mergedPosId = mergedPosData
          .map(el => el.posId)
          .filter(el => el !== null);
        this.mergedPosId = _.uniq(mergedPosId);
      }
      this.mergedPosData = mergedPosData;
      await this.init();
      this.$modal.show("modal-add-clients");
    },
    hideModal() {
      this.$modal.hide("modal-add-clients");
    },
    sortChange(params) {
      this.sorting = params;
      let sort_query = {};
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            sort_query.sortby = key;
            sort_query.sortdesc = value === "desc" ? true : false;
          }
        }
      }
      if (Object.keys(sort_query).length > 0) {
        const { sortby, sortdesc } = sort_query;
        this.tableData.sort((a, b) => {
          if (sortdesc) {
            return a[sortby] < b[sortby] ? 1 : b[sortby] < a[sortby] ? -1 : 0;
          } else {
            return a[sortby] > b[sortby] ? 1 : b[sortby] > a[sortby] ? -1 : 0;
          }
        });
      } else {
        this.tableData = this.sourceData;
      }
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable-additionalpos"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.searchTableData();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.searchTableData();
    },
    searchTableData() {
      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: Object.values(value)
          });
        }
      }
      let filter_columns = document.querySelectorAll(
        "#datatable-additionalpos .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          if (
            filter_item.columnField === "route_name" ||
            filter_item.columnField === "brand_name" ||
            filter_item.columnField === "pos_name" ||
            filter_item.columnField === "chain_name" ||
            filter_item.columnField === "channel_name" ||
            filter_item.columnField === "pos_address" ||
            filter_item.columnField === "pos_postalcode" ||
            filter_item.columnField === "pos_town"
          ) {
            this.tableData = this.tableData.filter(el =>
              filter_item.filterValue.includes(el[filter_item.columnField])
            );
          }
          return filter_item;
        });
      } else {
        this.tableData = this.sourceData;
      }
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      // query_params.push("page=" + this.pageIndex);
      // query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (
            key === "startKM" ||
            key === "endKM" ||
            key === "totalKM"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#datatable-additionalpos .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "worksession/addclients";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.close();
      } catch (error) {
        this.close();
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async onChangeRoutes() {
      this.selectedRoutes = this.selectedRouteIds;
    },
    async onClickRemoveRoute(routeId) {
      this.selectedRoutes = this.selectedRoutes.filter(el => el.id !== routeId);
      this.selectedRouteIds = this.selectedRoutes.map(el => el.id);
    },
    async onChangePOS() {
      this.selectedPOS = this.selectedPosIds;
    },
    async onClickRemovePos(posId) {
      this.selectedPOS = this.selectedPOS.filter(el => el.id !== posId);
      this.selectedPosIds = this.selectedPOS.map(el => el.id);
    },
    async onClickLoadRoutes() {
      if (
        !this.selectedRoutes ||
        this.selectedRoutes.length === 0 ||
        !this.selectedDate
      ) {
        logError("debes seleccionar rutas y fecha.");
        return;
      }
      this.loadTableData();
    },
    async onClickLoadPOS() {
      if (!this.selectedPOS || this.selectedPOS.length === 0) {
        logError("debes seleccionar POS.");
        return;
      }
      this.selectedDate = new Date().toISOString().slice(0, 10);
      this.loadTableData();
    },
    async onClickAddSelection() {
      if (this.selectedRowKeys.length === 0) {
        logError("Debe seleccionar más una opción.");
        return;
      }
      this.$refs.confirmDialogAddClient.showModal();
    },
    async confirmSelectionCriteria(addCredential) {
      if (!addCredential) return;
      let fetch_url = "worksession/addselections";
      let formdata = {
        selectedRowKeys: this.selectedRowKeys,
        selectedDate: this.selectedDate,
        addCredential
      };
      let response = await ApiService.post(fetch_url, formdata);
      if (response.success) {
        await this.parentInit(this.worksessionId);
        this.hideModal();
      } else {
        logError("We can't add new visit !");
      }
    },
    generateSearchParams(tableData) {
      this.filterQueryParams.route_name = _.uniq(
        tableData.map(el => el.route_name)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.brand_name = _.uniq(
        tableData.map(el => el.brand_name)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.pos_name = _.uniq(
        tableData.map(el => el.pos_name)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.chain_name = _.uniq(
        tableData.map(el => el.chain_name)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.channel_name = _.uniq(
        tableData.map(el => el.channel_name)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.pos_address = _.uniq(
        tableData.map(el => el.pos_address)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.pos_postalcode = _.uniq(
        tableData.map(el => el.pos_postalcode)
      ).filter(el => el && el !== null && el !== "");
      this.filterQueryParams.pos_town = _.uniq(
        tableData.map(el => el.pos_town)
      ).filter(el => el && el !== null && el !== "");
    },
    async loadTableData() {
      this.show();
      let fetch_url = "worksession/generateadditionalpos";
      let formdata = {
        selectedRoutes: this.selectedRoutes,
        selectedDate: this.selectedDate,
        selectedPOS: this.selectedPOS,
        worksessionId: this.worksessionId,
        mergedPosId: this.mergedPosId
      };
      try {
        let response = await ApiService.post(fetch_url, formdata);
        this.tableData = response.data;
        this.sourceData = this.tableData;
        this.generateSearchParams(this.tableData);
        if (this.tableData.length > 0) {
          this.showEmpty = false;
          let isAlreadyAdded = false;
          this.tableData.map(el => {
            if (el.alreadyAdded) isAlreadyAdded = true;
            return el;
          });
          if (isAlreadyAdded) {
            logError("El punto de venta ya pertenece a la ruta.");
          }
        } else {
          this.showEmpty = true;
        }
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async init() {
      this.tableData = [];
      this.sourceData = [];
      this.showEmpty = true;
      this.filtered_routes = [];
      this.selectedRouteIds = [];
      this.selectedRoutes = [];
      this.filtered_pos = [];
      this.selectedPosIds = [];
      this.selectedPOS = [];
      this.selectedDate = null;
      this.selectedRowKeys = [];
      await this.get_search_routes("");
      await this.get_search_pos("");
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.table-body-cell-addclient-pink {
  background: #d9bbc4 !important;
}
.table-body-cell-addclient-color-red {
  color: #f00 !important;
}
</style>
