<template>
  <div>
    <modal
      name="modal-optimized-pos-googlemap"
      :width="`90%`"
      :height="`auto`"
      scrollable
      @opened="onModalOpened"
      :clickToClose="false"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div ref="pos_google_map" class="pos_google_map"></div>
            </v-col>
          </v-row>
          <v-row v-if="!optimizedPos">
            <v-col cols="12" sm="12" md="12">
              <h5>There is no data to display !</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "DialogOptimizedPOS",
  components: {},
  data: function() {
    return {
      worksessionId: null,
      optimizedPos: null,
      sharedLink: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async showModal(worksessionId, optimizedPos) {
      await this.init(worksessionId, optimizedPos);
      this.$modal.show("modal-optimized-pos-googlemap");
    },
    hideModal() {
      this.$modal.hide("modal-optimized-pos-googlemap");
    },
    onModalOpened() {
      this.initMap();
    },
    initMap() {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      var mapOptions = {
        // zoom: 14,
        // center: haight,
      };
      var map = new window.google.maps.Map(
        this.$refs.pos_google_map,
        mapOptions
      );
      directionsRenderer.setMap(map);
      if (this.optimizedPos.length > 0) {
        let posListLength = this.optimizedPos.length;
        let pos_start = this.optimizedPos[0];
        let pos_end = this.optimizedPos[posListLength - 1];
        let startPoint = new window.google.maps.LatLng(
          pos_start.lat,
          pos_start.lng
        );
        let endPoint = new window.google.maps.LatLng(pos_end.lat, pos_end.lng);
        let waypoints = [];
        let waypointsLabels = [];
        if (posListLength > 2) {
          for (let i = 1; i < posListLength - 1; i++) {
            let posItem = this.optimizedPos[i];
            let posItemPoint = new window.google.maps.LatLng(
              posItem.lat,
              posItem.lng
            );
            waypoints.push({ location: posItemPoint });
            waypointsLabels.push(`${posItem.lat},${posItem.lng}`);
          }
        }
        this.calculateAndDisplayRoute(
          directionsService,
          directionsRenderer,
          startPoint,
          endPoint,
          waypoints
        );

        let startPointLabel = `${pos_start.lat},${pos_start.lng}`;
        let endPointLabel = `${pos_end.lat},${pos_end.lng}`;
        let travelMode = window.google.maps.TravelMode["DRIVING"];
        this.sharedLink = `https://www.google.com/maps/dir/?api=1&origin=${startPointLabel}&destination=${endPointLabel}&travelmode=${travelMode}`;
        if (waypointsLabels.length > 0) {
          this.sharedLink += `&waypoints=${waypointsLabels.join("|")}`;
        }
        console.log("sharedLink - ", this.sharedLink);
        window.open(this.sharedLink);
      }
    },
    calculateAndDisplayRoute(
      directionsService,
      directionsRenderer,
      pos_start,
      pos_end,
      waypoints
    ) {
      console.log("pos_start, pos_end - ", pos_start, pos_end);
      var request = {
        origin: pos_start,
        destination: pos_end,
        // Note that JavaScript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        travelMode: window.google.maps.TravelMode["DRIVING"],
      };
      if (waypoints.length > 0) {
        request.waypoints = waypoints;
      }
      directionsService.route(request, function(response, status) {
        console.log("response - ", response);
        if (status == "OK") {
          directionsRenderer.setDirections(response);
        }
      });
    },

    async init(worksessionId, optimizedPos) {
      this.worksessionId = worksessionId;
      this.optimizedPos = optimizedPos.map((el) => {
        let pushItem = { ...el };
        if (el?.po) {
          pushItem.lat = el.po.latitude;
          pushItem.lng = el.po.longitude;
        }
        return pushItem;
      });
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style>
.pos_google_map {
  width: 100%;
  height: 80vh;
}
</style>
