<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <b-alert v-if="isDataLoading" show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <div v-else class="d-flex flex-column">
      <div class="border d-flex justify-content-between align-center mb-2">
        <router-link :to="`/session/dashboard`">
          <b-button class="mr-1" variant="outline-secondary" size="sm">
            <b-icon icon="arrow-left"></b-icon> volver
          </b-button>
        </router-link>

        <span class="trencadis-title">
          Mi Ruta <b>{{ this.mainRoute ? `: ${this.mainRoute.name}` : "" }}</b>
        </span>
        <router-link
          :to="`/session/newpos/${this.id}`"
          v-if="this.mainRoute"
          style="color: #fff;"
        >
          <b-button variant="primary" size="sm" style="">
            <b-icon icon="plus"></b-icon> Alta Cliente
          </b-button>
        </router-link>
        <span v-else>&nbsp;</span>
      </div>

      <div class="my-title mt-3 d-flex align-center">
        <span class="badge badge-info titlebadge">Resultados</span>
      </div>

      <div class="">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="d-flex justify-content-center text-center"
          >
            <div class="table_container" style="width: 100%; overflow-x: auto;">
              <table
                class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense"
                v-if="
                  meta &&
                    meta?.results_for_ps_sales_values &&
                    meta.results_for_ps_sales_values?.brandList
                "
              >
                <tr>
                  <th class="stats-th-title" rowspan="2"></th>
                  <th class="stats-th-title" rowspan="2"></th>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <th
                      class="stats-th"
                      colspan="3"
                      :key="`tb_brand_key_${tb_brand_key}`"
                    >
                      {{ brandItem.name }}
                    </th>
                  </template>
                </tr>
                <tr>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <th
                      class="stats-th"
                      :key="`tb_brand_obj_key_${tb_brand_key}`"
                    >
                      Obj.
                    </th>
                    <th
                      class="stats-th"
                      :key="`tb_brand_real_key_${tb_brand_key}`"
                    >
                      Real
                    </th>
                    <th
                      class="stats-th"
                      :key="`tb_brand_diff_key_${tb_brand_key}`"
                    >
                      Vs ult Vis.
                    </th>
                  </template>
                </tr>
                <tr
                  v-if="meta?.results_for_ps_sales_values?.ps_values?.realToday"
                >
                  <td rowspan="2">
                    <b>Perfect Store</b>
                  </td>
                  <td>
                    <b>{{ $t("Today") }}</b>
                  </td>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <td :key="`tb_today_values_brand_obj_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].objective,
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                    <td :key="`tb_today_values_real_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].ps_value,
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                    <td :key="`tb_today_values_diff_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].diff_vs_last,
                          meta.results_for_ps_sales_values.ps_values.realToday[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                  </template>
                </tr>
                <tr v-if="meta?.results_for_ps_sales_values?.ps_values?.month">
                  <td>
                    <b>{{ $t("Month") }}</b>
                  </td>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <td :key="`tb_month_values_brand_obj_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].objective,
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                    <td :key="`tb_month_values_real_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].ps_value,
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                    <td :key="`tb_month_values_diff_key_${tb_brand_key}`">
                      {{
                        getAverageValue(
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].diff_vs_last,
                          meta.results_for_ps_sales_values.ps_values.month[
                            brandItem.id
                          ].count
                        )
                      }}
                    </td>
                  </template>
                </tr>
                <tr
                  v-if="meta?.results_for_ps_sales_values?.sales_values?.today"
                >
                  <td rowspan="2">
                    <b>{{ $t("brands.Sales") }}</b>
                  </td>
                  <td>
                    <b>{{ $t("Today") }}</b>
                  </td>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <td :key="`tb_today_values_brand_obj_key_${tb_brand_key}`">
                      {{
                        meta.results_for_ps_sales_values.sales_values.today[
                          brandItem.id
                        ].objective
                      }}
                    </td>
                    <td :key="`tb_today_values_real_key_${tb_brand_key}`">
                      {{
                        meta.results_for_ps_sales_values.sales_values.today[
                          brandItem.id
                        ].sales
                      }}
                    </td>
                    <td :key="`tb_today_values_diff_key_${tb_brand_key}`">
                      {{
                        meta.results_for_ps_sales_values.sales_values.today[
                          brandItem.id
                        ].diff_vs_last
                      }}
                    </td>
                  </template>
                </tr>
                <tr
                  v-if="meta?.results_for_ps_sales_values?.sales_values?.month"
                >
                  <td>
                    <b>{{ $t("Month") }}</b>
                  </td>
                  <template
                    v-for="(brandItem, tb_brand_key) in meta
                      .results_for_ps_sales_values.brandList"
                  >
                    <td
                      :key="
                        `tb_month_sales_values_brand_obj_key_${tb_brand_key}`
                      "
                    >
                      {{
                        meta.results_for_ps_sales_values.sales_values.month[
                          brandItem.id
                        ].objective
                      }}
                    </td>
                    <td :key="`tb_month_sales_values_real_key_${tb_brand_key}`">
                      {{
                        meta.results_for_ps_sales_values.sales_values.month[
                          brandItem.id
                        ].sales
                      }}
                    </td>
                    <td :key="`tb_month_sales_values_diff_key_${tb_brand_key}`">
                      {{
                        meta.results_for_ps_sales_values.sales_values.month[
                          brandItem.id
                        ].diff_vs_last
                      }}
                    </td>
                  </template>
                </tr>
              </table>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="d-flex justify-content-center text-center"
            v-if="
              meta &&
                meta?.results_for_action_push &&
                meta?.results_for_action_push.length > 0
            "
          >
            <table
              class="table b-table table-striped table-bordered table-sm b-table-stacked-sm mr-2 routepage_ps_table table-dense"
            >
              <tr>
                <th class="stats-th-title"></th>
                <th class="stats-th">Objective</th>
                <th class="stats-th">Real</th>
                <th class="stats-th">Diff</th>
              </tr>
              <template
                v-for="(actionItem,
                actionItemKey) in meta.results_for_action_push"
              >
                <tr :key="`action_list_${actionItemKey}`">
                  <td>
                    <b>{{ actionItem.name }}</b>
                  </td>
                  <td class="text-center">{{ actionItem.objectiveValue }}</td>
                  <td class="text-center">{{ actionItem.sumOfRealValues }}</td>
                  <td class="text-center">{{ actionItem.diff_vs_last }}</td>
                </tr>
              </template>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="container mt-2">
        <routeKPIs v-if="id" :worksessionId="id" />
      </div>

      <div class="my-title mt-3 d-flex align-center">
        <span class="badge badge-info titlebadge">CLIENTES A VISITAR</span>
      </div>

      <v-row class="mt-2">
        <v-col cols="12" sm="10" md="10">
          <table
            border="1"
            class="table b-table table-striped table-bordered table-sm b-table-stacked-sm text-center text-sm table-dense"
            v-if="routeVisitStatus"
          >
            <colgroup>
              <col width="20%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <tr style="color: white;">
              <th
                style="border-left: 1px solid white; border-top: 1px solid white;"
              ></th>
              <th style="background: #4472c4">Total</th>
              <th style="background: #70ad47">{{ $t("Positives") }}</th>
              <th style="background: #c00000">Negativas</th>
              <th style="background: #808080">{{ $t("Pending") }}</th>
              <th style="background: #ffc000">A Recuperar</th>
            </tr>
            <tr>
              <td><b>Visitas</b></td>
              <td>{{ routeVisitStatus.total }}</td>
              <td>{{ routeVisitStatus.positive }}</td>
              <td>{{ routeVisitStatus.negotive_incomplete }}</td>
              <td>{{ routeVisitStatus.pending }}</td>
              <td>{{ routeVisitStatus.recover }}</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <b-button
            @click="showAddClientsDialog()"
            style="font-size: 13px;width: 100%; padding: 4px"
            variant="primary"
            class="mr-1"
            :disabled="isLoadingDialogAddclients"
          >
            <b-spinner
              small
              label="Spinning"
              v-if="isLoadingDialogAddclients"
            ></b-spinner>
            <b-icon v-else icon="plus-circle"></b-icon>
            añadir clientes
          </b-button>
        </v-col>
      </v-row>

      <div class="d-flex flex-wrap">
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.brandId"
            v-bind:label="$t('brands.brand')"
            :items="brandList"
            item-text="name"
            dense
            item-value="id"
            multiple
            clearable
            outlined
            hide-no-data
            hide-details
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.visitType"
            v-bind:label="$t('Type')"
            :items="visitTypesSelect"
            item-text="label"
            dense
            item-value="value"
            multiple
            clearable
            outlined
            hide-no-data
            hide-details
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.labelId"
            v-bind:label="$t('label')"
            :items="labelList"
            item-text="name"
            item-value="id"
            multiple
            dense
            clearable
            outlined
            hide-no-data
            hide-details
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.chainId"
            v-bind:label="$t('chain')"
            :items="chainList"
            item-text="name"
            item-value="id"
            multiple
            dense
            clearable
            outlined
            hide-no-data
            hide-details
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.pos_details"
            v-bind:label="`POS`"
            :items="pos_details"
            item-text="name"
            item-value="id"
            multiple
            clearable
            outlined
            dense
            hide-no-data
            hide-details
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-autocomplete
            v-model="filterCriteria.town"
            v-bind:label="$t('town')"
            :items="townList"
            item-text="name"
            item-value="id"
            multiple
            dense
            clearable
            outlined
            @change="onChangeFilter"
          ></v-autocomplete>
        </div>
        <div class="py-0">
          <v-checkbox
            class="my-0"
            @change="checkOrderedPOS"
            v-model="filterCriteria.orderedPOS"
            :label="`Pedido`"
          ></v-checkbox>
        </div>
      </div>

      <b-alert v-if="isDataLoading" show variant="info">
        <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
        cargando datos...
      </b-alert>
      <routePosList
        v-else-if="filteredPosList && filteredPosList.length"
        :filteredPosList="filteredPosList"
        :visitTypes="visitTypes"
        :isOnlyVisible="isEndedSession"
        :onPosClicked="onPosClicked"
        :onClickRemoveAdditionalItem="onClickRemoveAdditionalItem"
      ></routePosList>
      <b-alert v-else show variant="info">
        No hay datos...
      </b-alert>

      <DialogAddClient
        ref="dialogAddClients"
        :worksessionId="this.id"
        :parentInit="init"
        :visitTypes="visitTypes"
        :mainRoute="mainRoute"
      />
      <DialogStartKM
        v-model="showStartKMDialog"
        ref="dialogStartKM"
        :vehicleTypeList="vehicleTypeList"
        :session="session"
        :current_km_data="current_km_data"
        :mainRoute="mainRoute"
        @savingStartKM="response => savingStartKM(response)"
      />
    </div>
    <DialogOptimizedPOS ref="dialogOptimizedPOS" />
  </div>
</template>

<script>
import { ellipsis } from "@/utils";
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import _ from "lodash";
import moment from "moment";
import Multiselect from "vue-multiselect";
import DialogAddClient from "./DialogAddClient.vue";
import DialogOptimizedPOS from "./DialogOptimizedPOS.vue";
import routePosList from "./routePosList.vue";
import sessionTagLabels from "@/components/sessionTagLabels";
import { logInfo, logError } from "@/utils";
import DialogStartKM from "./DialogStartKM.vue";
import routeKPIs from "./routeKPIs.vue";

export default {
  name: "Session",
  components: {
    Multiselect,
    DialogAddClient,
    sessionTagLabels,
    routePosList,
    DialogOptimizedPOS,
    DialogStartKM,
    routeKPIs
  },
  data() {
    return {
      id: null,
      session: null,
      meta: null,
      posList: [],
      additionalPosList: [],
      filteredPosList: [],
      sourcePosList: [],
      routeList: [],
      selectedRoutes: null,
      mainRoute: null,

      filterCriteria: {},
      brandList: [],
      visitTypes: [],
      visitTypesSelect: [],
      routeVisitStatus: null,
      labelList: [],
      chainList: [],
      townList: [],
      pos_details: [],

      visitTypeIcons: {
        PRESENT: "geo-alt-fill",
        PHONE: "telephone",
        AGENDA: "calendar",
        SCHEDULED: "calendar"
      },

      isDisplayOrderd: false,
      filterOnlySalesActiveItems: false,
      isOptimizedPos: false,

      isDataLoading: false,
      isLoadingOptimizedPOS: false,
      isLoadingDialogAddclients: false,
      startedWorksessionPosCount: 0,
      showStartKMDialog: false,
      vehicleTypeList: [],
      clickedPosItem: null,
      current_km_data: null,
      isLoadingSpinner: false
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    ...mapGetters("myroute", [
      "messageVisitAlert",
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession"
    ]),
    userLiquidationType() {
      let liquidationType = "TOTAL_KM";
      if (this.user && this.user?.liquidationType) {
        liquidationType = this.user.liquidationType;
      }
      return liquidationType;
    }
  },
  methods: {
    ellipsis,
    getAverageValue(value, count) {
      let ret = null;
      if (value == "-") return value;
      if (count) {
        ret = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / count;
        if (isNaN(ret)) ret = null;
        else ret = parseInt(ret);
      } else if (count == 0) {
        ret = 0;
      }
      return ret;
    },
    async showAddClientsDialog() {
      this.isLoadingSpinner = true;
      this.isLoadingDialogAddclients = true;
      try {
        await this.$refs.dialogAddClients.showModal(this.meta?.mergedPosData);
      } catch (error) {}
      this.isLoadingSpinner = false;
      this.isLoadingDialogAddclients = false;
    },
    async onClickPosOrder() {
      // console.log("CLICKED POS ORDER !");
      // this.isDisplayOrderd = !this.isDisplayOrderd;
      // this.init(this.id);

      // console.log("CLICKED POS OPTIMIZED !");
      this.isLoadingOptimizedPOS = true;
      let formData = {
        worksessionId: this.id,
        currentPosition: this.currentPosition
      };
      if (this.meta && this.meta?.mergedPosData) {
        let posList = [];
        this.meta.mergedPosData.map(el => {
          if (el.visitType !== "PRESENT") return;
          if (el.po) posList.push(el.po);
        });
        formData["posList"] = posList;
      }
      let response = await ApiService.post(
        `worksession/geopositioning/orderpos`,
        formData
      );
      if (response?.success) {
        /****** Open google-map modal and new window page ******/
        // this.isOptimizedPos = true;
        // this.init(this.id);
        // this.isLoadingOptimizedPOS = false;

        /****** Open new window page for google-map ******/
        this.isLoadingOptimizedPOS = false;
        if (
          response?.optimizedPos &&
          Array.isArray(response.optimizedPos) &&
          response.optimizedPos.length > 0
        ) {
          let optimizedPos = response.optimizedPos.map(el => {
            let pushItem = { ...el };
            if (el?.po) {
              pushItem.lat = el.po.latitude;
              pushItem.lng = el.po.longitude;
            }
            return pushItem;
          });
          let posListLength = optimizedPos.length;
          let pos_start = optimizedPos[0];
          let pos_end = optimizedPos[posListLength - 1];
          let waypointsLabels = [];
          if (posListLength > 2) {
            for (let i = 0; i < posListLength - 1; i++) {
              let posItem = optimizedPos[i];
              waypointsLabels.push(`${posItem.lat},${posItem.lng}`);
            }
          }
          // let startPointLabel = `${pos_start.lat},${pos_start.lng}`;
          let startPointLabel = `${this.currentPosition.latitude},${this.currentPosition.longitude}`;
          let endPointLabel = `${pos_end.lat},${pos_end.lng}`;
          let travelMode = window.google.maps.TravelMode["DRIVING"];
          let sharedLink = `https://www.google.com/maps/dir/?api=1&origin=${startPointLabel}&destination=${endPointLabel}&travelmode=${travelMode}`;
          if (waypointsLabels.length > 0) {
            sharedLink += `&waypoints=${waypointsLabels.join("|")}`;
          }
          console.log("sharedLink - ", sharedLink);
          window.open(sharedLink);

          window.location.reload(true);
        }
      } else {
        logError("We can't order POS.");
        this.isLoadingOptimizedPOS = false;
      }
    },
    async savingStartKM(response) {
      this.showStartKMDialog = false;
      if (this.isNotEmptyMessageVisitAlert(this.messageVisitAlert)) {
        // should check if we can have empty message or not
        this.$store.dispatch("myroute/showMessageVisitAlert");
      }
      await this.posInitialize();
    },
    async posInitialize() {
      let pos = this.clickedPosItem;
      const { routeId, posId, visitType, brand_list } = pos;
      const worksessionId = this.id;
      const formdata = {
        routeId,
        posId,
        visitType,
        worksessionId,
        brand_list,
        currentPosition: this.currentPosition
        // gmapAddress: this.currentGmapAddress
      };
      this.isLoadingSpinner = true;
      try {
        let { data } = await ApiService.post(
          `worksession/posinitialize`,
          formdata
        );
        if (data) {
          console.log(data);
          if (data.visitType === "AGENDA") {
            this.$router.push({
              path: `schedule/${data.id}`
            });
          } else {
            // this.$router.push({
            //   path: `pos/${data.id}`,
            // });
            this.$router.push({
              name: "router_worksessionpospage",
              params: {
                id: worksessionId,
                worksessionPosId: data.id,
                frompage: "route"
              }
            });
          }
        }
        this.isLoadingSpinner = false;
      } catch (error) {
        this.isLoadingSpinner = false;
      }
    },
    async onPosClicked(pos) {
      this.clickedPosItem = pos;
      await this.posInitialize();
      // this.showStartKMDialog = false;
      // if (this.isNotEmptyMessageVisitAlert(this.messageVisitAlert)) {
      //   // should check if we can have empty message or not
      //   this.$store.dispatch("myroute/showMessageVisitAlert");
      // }
    },
    async onClickRemoveAdditionalItem(additionalIds) {
      console.log(additionalIds);
      if (additionalIds.length > 0 && confirm(this.$t("confirmdelete"))) {
        let fetch_url = "worksession/removeadditionalitems";
        let formdata = {
          additionalIds
        };
        let response = await ApiService.post(fetch_url, formdata);
        if (response.success) {
          await this.init(this.id);
        }
      }
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    async checkOrderedPOS(newValue) {
      this.filterOnlySalesActiveItems = newValue;
      this.onChangeFilter();
    },
    onChangeFilter() {
      console.log(this.filterOnlySalesActiveItems);
      let targetList = this.sourcePosList;
      if (this.filterOnlySalesActiveItems) {
        targetList = targetList.filter(el => el.status.isHasSales);
      }
      console.log(targetList);
      if (Object.keys(this.filterCriteria).length > 0) {
        let pos_list = targetList;
        for (const [filterKey, filterValue] of Object.entries(
          this.filterCriteria
        )) {
          if (filterValue.length > 0) {
            if (filterKey === "brandId") {
              pos_list = pos_list.filter(posEl => {
                let filtered = posEl.brand_list.filter(brandEl =>
                  filterValue.includes(brandEl.id)
                );
                return filtered.length > 0;
              });
            }
            if (filterKey === "labelId") {
              pos_list = pos_list.filter(posEl => {
                let filtered = posEl["po"].postaglabels.filter(labels =>
                  filterValue.includes(labels.id)
                );
                return filtered.length > 0;
              });
            }
            if (filterKey === "visitType") {
              pos_list = pos_list.filter(posEl =>
                filterValue.includes(posEl[filterKey])
              );
            }
            if (filterKey === "chainId" || filterKey === "town") {
              pos_list = pos_list.filter(posEl =>
                filterValue.includes(posEl["po"][filterKey])
              );
            }
            if (filterKey === "pos_details") {
              pos_list = pos_list.filter(posEl =>
                filterValue.includes(`${posEl.routeId}-${posEl.posId}`)
              );
            }
          }
        }
        this.filteredPosList = pos_list;
      } else {
        this.filteredPosList = targetList;
      }
    },
    async init(id) {
      this.isDataLoading = true;
      try {
        this.id = id;
        let fetch_url = `worksession/${this.id}`;
        if (this.isDisplayOrderd) {
          fetch_url += `?isOrdered=true`;
        }
        if (this.isOptimizedPos) {
          fetch_url += `?isOptimizedPos=true`;
        }
        const {
          data,
          meta,
          visitTypes,
          user_route,
          isOptimizedPos,
          startedWorksessionPosCount,
          vehicleTypeList,
          current_km_data
        } = await ApiService.get(fetch_url);
        if (user_route) {
          if (Array.isArray(user_route) && user_route.length > 0) {
            this.mainRoute = user_route[0];
          } else {
            this.mainRoute = null;
          }
        }
        this.startedWorksessionPosCount = startedWorksessionPosCount;
        this.vehicleTypeList = vehicleTypeList;
        this.current_km_data = current_km_data;
        this.visitTypes = visitTypes;
        this.visitTypesSelect = [];
        for (const [key, value] of Object.entries(visitTypes)) {
          this.visitTypesSelect.push({
            label: value,
            value: key
          });
        }
        this.session = data;
        this.meta = meta;
        if (this.meta?.routeVisitStatus) {
          this.routeVisitStatus = this.meta.routeVisitStatus;
        } else {
          this.routeVisitStatus = {
            total: 0,
            positive: 0,
            negotive_incomplete: 0,
            pending: 0,
            recover: 0
          };
        }

        //let's join all data
        const { mergedPosData } = this.meta;
        // this.additionalPosList = additionalPOSdata;
        console.log("this.posList - ", this.posList);
        console.log("this.additionalPosList - ", this.additionalPosList);
        console.log("this.mergedPosData - ", mergedPosData);
        this.filteredPosList = mergedPosData.map(el => {
          let item = { ...el };
          if (item.status.scheduleDateTime) {
            item.status.originalScheduleDateTime = moment(
              item.status.originalScheduleDateTime
            )
              .local()
              .format("DD/MM/YYYY HH:mm");
          }
          return item;
        });
        this.sourcePosList = this.filteredPosList;
        this.brandList = _.uniqBy(
          this.filteredPosList.map(el => el["brand_list"].map(rp => rp)).flat(),
          "id"
        );
        this.labelList = _.uniqBy(
          this.filteredPosList
            .map(el => el["po"]["postaglabels"].map(rp => rp))
            .flat(),
          "id"
        );
        this.chainList = _.uniqBy(
          this.filteredPosList.map(el => el["po"]["chain"]).flat(),
          "id"
        );
        this.townList = _.uniqBy(
          this.filteredPosList.map(el => el["po"]["town"])
        );
        this.filteredPosList.map(el => {
          let detail_item = [];
          if (el.po) {
            if (!_.isEmpty(el["po"]["name"])) {
              detail_item.push(el["po"]["name"]);
            }
            let idPosBrandList = [];
            idPosBrandList = this.getIdPosBrandList(el);
            idPosBrandList = idPosBrandList.map(el => el.idPosBrand);
            if (idPosBrandList.length > 0) {
              detail_item.push(idPosBrandList.join(", "));
            } else {
              detail_item.push(" - ");
            }
          }
          detail_item.push(el.posId);
          if (!_.isEmpty(el["po"]["address"])) {
            detail_item.push(el["po"]["address"]);
          }
          this.pos_details.push({
            id: `${el.routeId}-${el.posId}`,
            name: detail_item.join(" / ")
          });
        });
        this.pos_details = _.uniqBy(this.pos_details);
        this.isDataLoading = false;

        if (this.isOptimizedPos) {
          console.log("SHOW MODAL FOR GOOGLE MAP !");
          this.$refs.dialogOptimizedPOS.showModal(
            this.id,
            this.meta?.optimizedPos
          );
        }
      } catch (error) {
        logError("Network error !");
        console.log("error - ", error);
        this.isDataLoading = false;
      }
    }
  },

  async mounted() {
    this.init(this.$route.params.id);
  }
};
</script>

<style>
.stats-th-title {
  width: 120px;
}

.stats-th {
  width: 80px;
}

.myroute_delete_button {
  padding: 7px !important;
  border-radius: 50%;
  background: #d94645 !important;
  color: #fff !important;
  min-width: auto !important;
}
</style>
