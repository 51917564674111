var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isDataLoading)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('b-icon',{attrs:{"icon":"arrow-counterclockwise","animation":"spin-reverse"}}),_vm._v(" cargando datos... ")],1):(_vm.table_data)?_vm._l((_vm.table_data),function(brand,i){return [_c('v-row',{key:`route_kpi_${i}`},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h6',[_vm._v(_vm._s(brand.name))]),_c('div',{staticClass:"table_container",staticStyle:{"width":"100%","overflow-x":"auto"}},[_c('table',{staticClass:"table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense text-center"},[_c('tr',[_c('th',{key:`route_kpi_${brand.id}_column_table`,staticClass:"stats-th"}),_vm._l((brand.table_columns),function(surveyQuestion,columnKey){return [_c('th',{key:`route_kpi_${brand.id}_column_table_${columnKey}`,staticClass:"stats-th"},[_vm._v(" "+_vm._s(surveyQuestion.name)+" ")])]})],2),_c('tr',[_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("Today")))])]),_vm._l((brand.table_columns),function(surveyQuestion,columnKey){return [_c('td',{key:`route_kpi_${brand.id}_column_table_${columnKey}`,class:`stats-th ${_vm.calcBackgroundColor(
                      brand.today_list[surveyQuestion.id]
                    )}`},[(
                      Object.keys(brand.today_list)
                        .map(el => parseInt(el))
                        .includes(surveyQuestion.id)
                    )?[_vm._v(" "+_vm._s(_vm.numberFormatEsCustomDecimalCount( brand.today_list[surveyQuestion.id]?.sumOfValueStr, 1 ))+" vs "+_vm._s(_vm.numberFormatEsCustomDecimalCount( brand.today_list[surveyQuestion.id] ?.sumOfkpiObjectiveValueStr, 1 ))+" ")]:_c('span',[_vm._v(" ")])],2)]})],2),_c('tr',[_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("Month")))])]),_vm._l((brand.table_columns),function(surveyQuestion,columnKey){return [_c('td',{key:`route_kpi_${brand.id}_column_table_${columnKey}`,class:`stats-th ${_vm.calcBackgroundColor(
                      brand.month_list[surveyQuestion.id]
                    )}`},[(
                      Object.keys(brand.month_list)
                        .map(el => parseInt(el))
                        .includes(surveyQuestion.id)
                    )?[_vm._v(" "+_vm._s(_vm.numberFormatEsCustomDecimalCount( brand.month_list[surveyQuestion.id]?.sumOfValueStr, 1 ))+" vs "+_vm._s(_vm.numberFormatEsCustomDecimalCount( brand.month_list[surveyQuestion.id] ?.sumOfkpiObjectiveValueStr, 1 ))+" ")]:_c('span',[_vm._v(" ")])],2)]})],2)])])])],1)]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }